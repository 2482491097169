import axios from '../utils/axios';
import authHeader from './auth-header';
import { ADMIN_URL } from '../config/vars';

const API_URL = `${ADMIN_URL}/products`;

const getProducts = (params = { active: true }) => {
  return axios.get(API_URL, { headers: authHeader(), params });
};

const getProduct = (product, version) => {
  const params = {};
  return axios.get(`${API_URL}/${product}/${version}`, { headers: authHeader(), params });
};

export default {
  getProducts,
  getProduct
};
