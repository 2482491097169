import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import CheckButton from 'react-validation/build/button';

import Serials from './Serials';
import SerialService from '../services/serial.service';
import ProductService from '../services/product.service';

const Manage = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState('');

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [legacyProducts, setLegacyProducts] = useState(false);
  const [search, setSearch] = useState('');
  const [serials, setSerials] = useState({});

  const reloadProducts = (active = true) => {
    ProductService.getProducts({ active }).then(
      response => {
        setProducts(response.data);
      },
      error => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
  };

  const reloadSerials = data => {
    SerialService.getSerials(data)
      .then(response => {
        setSerials(response.data);
      })
      .catch(error => {
        console.error(error);
        const resMessage = (
          error.response &&
            error.response.data &&
            error.response.data.message) ||
              error.message ||
              error.toString();

        setMessage(resMessage);
        setSuccessful(false);
        return [];
      });
  };

  useEffect(() => {
    reloadProducts(!legacyProducts);
  }, [legacyProducts]);

  const onChangeProduct = e => {
    const newProduct = e.target.value;
    setProduct(newProduct);
  };

  const onLegacyProductsChange = e => {
    const newLegacyProducts = !!e.target.checked;
    setLegacyProducts(newLegacyProducts);
  };

  const onChangeSearch = e => {
    const newSearch = e.target.value;
    setSearch(newSearch);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      reloadSerials({ product, search });
    }
  };

  const handleNext = direction => {
    setMessage('');
    setSuccessful(false);

    const p = serials.page + direction;
    if (p >= 1 && p <= serials.total) {
      reloadSerials({ product, search, pagenum: p });
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {!successful && (
        <Form onSubmit={handleSubmit} ref={form}>
          <div>
            <div className="form-group">
              <label htmlFor="product">Product</label>
              <Select
                name="product"
                value={product}
                onChange={onChangeProduct}
              >
                <option value="">All products</option>
                {products && products.map(p => <option value={`${p.name}/${p.version}`} key={`${p.name}/${p.version}`}>{p.description}</option>)}
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="legacyProducts">Legacy Products</label>
              <Input
                type="checkbox"
                name="legacyProducts"
                checked={!!legacyProducts}
                onChange={onLegacyProductsChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="search">Search</label>
              <Input
                type="text"
                className="form-control"
                name="search"
                value={search}
                onChange={onChangeSearch}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block">Search</button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div
                className={successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
        )}
      </div>
      <Serials
        data={serials.data}
        page={serials.page}
        total={serials.total}
        count={serials.count}
      />
      <div className="flex-container row">
        {(serials.page > 1 && serials.total > 1) && (
          <div className="form-group row-item">
            <button className="btn btn-secondary" onClick={() => handleNext(-1)}>Prev</button>
          </div>
        )}
        {(serials.page < serials.total && serials.total > 1) && (
          <div className="form-group row-item">
            <button className="btn btn-secondary" onClick={() => handleNext(1)}>Next</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Manage;
