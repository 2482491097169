import axios from '../utils/axios';
import authHeader from './auth-header';
import { ADMIN_URL } from '../config/vars';

const API_URL = `${ADMIN_URL}/serials`;

const getSerials = ({ ...data }) => {
  const params = {};
  if (data.product) {
    const [product, version] = data.product.split('/');
    params.product = product;
    params.version = version;
  }
  if (data.search) {
    params.search = data.search;
  }
  if (data.pagenum) {
    params.pagenum = data.pagenum;
  }
  return axios.get(API_URL, { headers: authHeader(), params });
};

const getSerial = serial => {
  const params = {};
  return axios.get(`${API_URL}/${serial}`, { headers: authHeader(), params });
};

const getSerialStatus = serial => {
  if (serial.status === 'swap') {
    return 'One time swap';
  }
  if (serial.status) {
    return serial.status[0].toUpperCase() + serial.status.slice(1).toLowerCase();
  }
  return serial.valid ? 'Valid' : 'Unknown';
};

const createSerial = ({ ...data }) => {
  const [product, version] = data.product.split('/');
  const params = {
    product,
    version,
    comment: data.comments,
    count: data.activations,
  };
  ['expiration', 'name', 'organization', 'phone', 'email'].forEach(key => {
    if (data[key]) {
      params[key] = data[key];
    }
  });
  return axios
    .post(API_URL, params)
    .then(response => {
      return response;
    });
};

const createBulkSerials = ({ ...data }) => {
  const [product, version] = data.product.split('/');
  const params = {
    product,
    version,
    count: data.count,
  };
  const config = {
    headers: { Accept: 'text/plain' },
    responseType: 'text',
  };
  return axios
    .post(`${API_URL}/bulk`, params, config)
    .then(response => {
      return response;
    });
};

const modifySerial = serial => {
  const params = { ...serial };
  if (params.comments !== undefined) {
    params.comment = params.comments;
    delete params.comments;
  }
  return axios
    .patch(`${API_URL}/${serial.serial}`, params)
    .then(response => {
      return response;
    });
};

const assignSerial = ({ ...data }) => {
  const params = { username: data.email };
  return axios
    .post(`${API_URL}/${data.serial}/assign`, params)
    .then(response => {
      return response;
    });
};

const clearActivations = serial => {
  return axios
    .delete(`${API_URL}/${serial}/activations`)
    .then(response => {
      return response;
    });
};

const activateSerial = ({ ...data }) => {
  const params = {
    mac: data.mac
  };
  const config = {
    headers: { Accept: 'application/octet-stream' },
    responseType: 'blob',
  };
  return axios
    .post(`${API_URL}/${data.serial}/activation`, params, config)
    .then(response => {
      return response;
    });
};

const createTrialReceipt = ({ ...data }) => {
  const [product, version] = data.product.split('/');
  const config = {
    headers: { Accept: 'application/octet-stream' },
    responseType: 'blob',
  };
  return axios
    .post(`${ADMIN_URL}/products/${product}/${version}/activation`, {}, config)
    .then(response => {
      return response;
    });
};

const createLegacyActivation = ({ ...data }) => {
  const params = {
    mac: data.mac,
    days: data.days,
  };
  if (data.serial) {
    return axios
      .post(`${API_URL}/${data.serial}/activation_key`, params)
      .then(response => {
        return response;
      });
  }
  const [product, version] = data.product.split('/');
  return axios
    .post(`${API_URL}/activation_key`, { product, version, netcheck: data.netcheck, ...params })
    .then(response => {
      return response;
    });
};

export default {
  getSerials,
  getSerial,
  getSerialStatus,
  createSerial,
  createBulkSerials,
  assignSerial,
  modifySerial,
  clearActivations,
  activateSerial,
  createTrialReceipt,
  createLegacyActivation,
};
