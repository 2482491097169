const getConfig = () => ({
  get host() {
    if (process.env.NODE_ENV === 'development') {
      // return 'https://license-test.digitalanarchy.com';
      return 'http://localhost:5000';
    }
    return `${window.location.protocol}//${window.location.host}`;
  },
});

export const HOST = getConfig().host;
export const ADMIN_URL = `${HOST}/api/v1/admin`;
