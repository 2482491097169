import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  // required,
  validEmail,
  validPhoneUS,
} from './validation';

import AuthService from '../services/auth.service';

const Login = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // One of 'sms', 'whatsapp', 'call', 'email'
  const [method, setMethod] = useState('');
  // List of 'sms', 'whatsapp', 'call', 'email'
  const [security, setSecurity] = useState(null);
  const [sendingCode, setSendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const missingPhone = security && security.sms === 'MISSING';
  let channel = '';

  const onSendCode = data => {
    setMessage('');
    setSendingCode(true);

    const { username, password, phone } = data;

    AuthService.sendCode(username, password, channel, phone).then(
      response => {
        if (response) {
          setMethod(channel);
          setSendingCode(false);
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setSendingCode(false);
        setMessage(resMessage);
      }
    );
  };

  const onAddPhone = data => {
    channel = 'sms';
    onSendCode(data);
  };

  const onLogin = data => {
    setMessage('');
    setLoading(true);

    const { username, password, code } = data;

    AuthService.login(username, password, code).then(
      response => {
        if (response.security && Object.keys(response.security).length > 0) {
          setSecurity(response.security);
          setLoading(false);
        } else {
          props.history.push('/profile');
          window.location.reload();
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const renderMissingPhone = () => (
    <form onSubmit={handleSubmit(onAddPhone)}>
      <div className="alert alert-danger" role="alert">
        Please enable two-factor authentication.
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone Number</label>
        <input
          type="text"
          className="form-control"
          name="phone"
          {...register('phone', {
            required: true,
            validate: validPhoneUS,
          })}
        />
        {errors.phone?.type === 'required' && (
          <div className="alert alert-danger" role="alert">
            Phone Number is required!
          </div>
        )}
        {errors.phone?.message}
      </div>
      <div className="form-group">
        <button className="btn btn-primary btn-block" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm" />
          )}
          <span>Confirm</span>
        </button>
      </div>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </form>
  );

  const renderSecurity = () => (
    <form>
      <div className="form-group">
        <div className="alert alert-danger" role="alert">
          A security code is required.
        </div>
        {security.sms && (
          <button
            className="btn btn-secondary btn-block"
            disabled={sendingCode}
            onClick={e => { channel = 'sms'; handleSubmit(onSendCode)(e); }}
          >
            {sendingCode && (
              <span className="spinner-border spinner-border-sm" />
            )}
            <span>Text {security.sms}</span>
          </button>
        )}
        {security.whatsapp && (
          <button
            className="btn btn-secondary btn-block"
            disabled={sendingCode}
            onClick={e => { channel = 'whatsapp'; handleSubmit(onSendCode)(e); }}
          >
            {sendingCode && (
              <span className="spinner-border spinner-border-sm" />
            )}
            <span>Message {security.whatsapp} with WhatsApp</span>
          </button>
        )}
        {security.call && (
          <button
            className="btn btn-secondary btn-block"
            disabled={sendingCode}
            onClick={e => { channel = 'call'; handleSubmit(onSendCode)(e); }}
          >
            {sendingCode && (
              <span className="spinner-border spinner-border-sm" />
            )}
            <span>Call to {security.call}</span>
          </button>
        )}
        {security.email && (
          <button
            className="btn btn-secondary btn-block"
            disabled={sendingCode}
            onClick={e => { channel = 'email'; handleSubmit(onSendCode)(e); }}
          >
            {sendingCode && (
              <span className="spinner-border spinner-border-sm" />
            )}
            <span>Email {security.email}</span>
          </button>
        )}
      </div>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </form>
  );

  const renderCode = () => (
    <form onSubmit={handleSubmit(onLogin)}>
      <div className="form-group">
        <label htmlFor="code">Security Code</label>
        <input
          type="text"
          className="form-control"
          name="code"
          {...register('code', {
            required: true,
          })}
        />
        {errors.code?.type === 'required' && (
          <div className="alert alert-danger" role="alert">
            Username is required!
          </div>
        )}
      </div>
      <div className="form-group">
        <button className="btn btn-primary btn-block" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm" />
          )}
          <span>Login</span>
        </button>
      </div>
      <div className="form-group">
        <button
          className="btn btn-secondary btn-block"
          disabled={sendingCode}
          onClick={e => { handleSubmit(onSendCode)(e); }}
        >
          {sendingCode && (
            <span className="spinner-border spinner-border-sm" />
          )}
          <span>Send another code</span>
        </button>
      </div>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </form>
  );

  const renderLogin = () => (
    <form onSubmit={handleSubmit(onLogin)}>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input
          type="text"
          className="form-control"
          name="username"
          {...register('username', {
            required: true,
            validate: validEmail,
          })}
        />
        {errors.username?.type === 'required' && (
          <div className="alert alert-danger" role="alert">
            Username is required!
          </div>
        )}
        {errors.username?.message}
      </div>

      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          className="form-control"
          name="password"
          {...register('password', {
            required: true,
          })}
        />
        {errors.password?.type === 'required' && (
          <div className="alert alert-danger" role="alert">
            Password is required!
          </div>
        )}
      </div>

      <div className="form-group">
        <button className="btn btn-primary btn-block" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm" />
          )}
          <span>Login</span>
        </button>
      </div>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </form>
  );

  const render = () => (
    <div>
      <div>
        {security && method && renderCode()}
      </div>
      <div>
        {missingPhone && security && !method && renderMissingPhone()}
      </div>
      <div>
        {!missingPhone && security && !method && renderSecurity()}
      </div>
      <div>
        {!missingPhone && !security && renderLogin()}
      </div>
    </div>
  );

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        {render()}
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired
};

Login.defaultProps = {
};

export default Login;
