import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Select from 'react-validation/build/select';
import CheckButton from 'react-validation/build/button';
import download from 'downloadjs';

import { required } from './validation';

import SerialService from '../services/serial.service';
import ProductService from '../services/product.service';

const downloadReceipt = product => {
  return SerialService.createTrialReceipt({ product }).then(response => {
    const receipt = response.data;
    if (receipt && response.headers['content-type'] === 'application/octet-stream') {
      download(receipt, 'anywhere.receipt', 'application/octet-stream');
    }
    return response;
  });
};

const Trial = (/* props */) => {
  const form = useRef();
  const checkBtn = useRef();

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState('');

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');

  const reloadProducts = (active = true) => {
    ProductService.getProducts({ active, receipts: true }).then(
      response => {
        setProducts(response.data);
      },
      error => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
  };

  useEffect(() => {
    reloadProducts(true);
  }, []);

  const onChangeProduct = e => {
    const newProduct = e.target.value;
    setProduct(newProduct);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      return downloadReceipt(product)
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
          return [];
        });
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {!successful && (
        <Form onSubmit={handleSubmit} ref={form}>
          <div>
            <p>Create an offline receipt.  It will be valid for 14 days on any machine.</p>
            <div className="form-group">
              <label htmlFor="product">Product</label>
              <Select
                name="product"
                value={product}
                onChange={onChangeProduct}
                validations={[required]}
              >
                <option value="">Select a product</option>
                {products && products.map(p => <option value={`${p.name}/${p.version}`} key={`${p.name}/${p.version}`}>{p.description}</option>)}
              </Select>
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block">Create</button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div
                className={successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
        )}
      </div>
    </div>
  );
};

export default Trial;
