import LocalStorage from '../services/localStorage';
import history from './history';
import axios from 'axios';
import { ADMIN_URL } from '../config/vars';

const REFRESH_URL = `${ADMIN_URL}/refresh`;
const LOGIN_URL = '/login';

const instance = axios.create();

const loginResponse = response => ([401, 403].includes(response.status));

instance.interceptors.request.use(
  config => {
    const token = LocalStorage.getAccessToken();
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: 'Bearer ' + token,
      },
    };
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { config: originalRequest } = error;
    if ((error.response && !loginResponse(error.response))
        || originalRequest.url.endsWith('/login')
        || originalRequest.url.endsWith('/login/send_code')) {
      return Promise.reject(error);
    }
    if (originalRequest.url.endsWith('/refresh')) {
      console.error('refresh token has expired');
      history.push(LOGIN_URL);
      window.location.reload();
      return Promise.reject(error);
    }

    if (!originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = LocalStorage.getRefreshToken();
      return axios.get(REFRESH_URL,
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`
          }
        })
        .then(response => {
          if (response.data) {
            LocalStorage.setToken(response.data);
            instance.defaults.headers.common.Authorization = `Bearer ${LocalStorage.getAccessToken()}`;
            return instance(originalRequest);
          }
        })
        .catch(refreshError => {
          if (refreshError.response && loginResponse(refreshError.response)) {
            console.error('refresh token has expired');
            history.push(LOGIN_URL);
            window.location.reload();
          }
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
