import axios from '../utils/axios';
import LocalStorage from './localStorage';
import { ADMIN_URL } from '../config/vars';

const API_URL = `${ADMIN_URL}/login`;

const sendCode = (username, password, method, phone = null) => {
  return axios
    .post(`${API_URL}/send_code`, {
      username,
      password,
      method,
      phone,
    })
    .then(() => true);
};

const login = (username, password, code = null) => {
  const payload = {
    admin: true,
    username,
    password,
  };
  if (code) {
    payload.code = code;
  }
  const remember = LocalStorage.getRememberToken();
  if (remember) {
    payload.remember = remember;
  }
  return axios
    .post(API_URL, payload)
    .then(response => {
      if (response.data && !response.security) {
        LocalStorage.setToken(response.data);
        LocalStorage.setUser(JSON.stringify({ username }));
      }

      return response.data;
    });
};

const logout = () => {
  LocalStorage.clearToken();
  LocalStorage.clearUser();
};

const getCurrentUser = () => {
  try {
    return JSON.parse(LocalStorage.getUser());
  } catch (e) {
    return '';
  }
};

export default {
  sendCode,
  login,
  logout,
  getCurrentUser
};
