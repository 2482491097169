import React, { useState, useEffect } from 'react';
// import { Switch, Route, Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsClipboard } from 'react-icons/bs';

import './App.css';

import AuthService from './services/auth.service';
import LocalStorage from './services/localStorage';

import Login from './components/Login';
import Home from './components/Home';
import Create from './components/Create';
import Trial from './components/Trial';
import Legacy from './components/Legacy';
import Manage from './components/Manage';
import SerialDetail from './components/SerialDetail';

const App = () => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      // setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      // setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
      setShowAdminBoard(true);
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  const copyToClipboard = () => {
    const token = LocalStorage.getAccessToken();
    const authorization = 'Bearer ' + token;
    navigator.clipboard.writeText(authorization);
  };

  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to="/" className="navbar-brand">
            License Tool
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to="/home" className="nav-link">
                Home
              </Link>
            </li>

            {showAdminBoard && (
              <>
                <li className="nav-item">
                  <Link to="/create" className="nav-link">
                    Create
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/trial" className="nav-link">
                    Trial
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/legacy" className="nav-link">
                    Legacy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/manage" className="nav-link">
                    Manage
                  </Link>
                </li>
              </>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/profile" className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
              <li className="nav-item">
                <button className="icon-btn" onClick={copyToClipboard}>
                  <BsClipboard />
                </button>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/login" className="nav-link">
                  Login
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Switch>
            <Route exact path={['/', '/home']} component={Home} />
            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/profile" component={Profile} />
            <Route exact path="/products" component={Products} /> */}
            <Route exact path="/create" component={Create} />
            <Route exact path="/trial" component={Trial} />
            <Route exact path="/legacy" component={Legacy} />
            <Route exact path="/manage" component={Manage} />
            <Route exact path="/serials/:id" component={SerialDetail} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
