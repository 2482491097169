import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SerialService from '../services/serial.service';

const { getSerialStatus } = SerialService;

const InvalidLink = styled(Link)`
  color: Red;
`;

const shortText = (text, maxChars) => {
  if (text && text.length > maxChars) {
    const words = text.split(/\s+/);
    const w = [];
    let count = 3;
    for (let i = 0; i < words.length && count < maxChars; i++) {
      w.push(words[i]);
      count += 1 + words[i].length;
    }
    return w.join(' ') + '...';
  }
  return text;
};

const renderSerial = serial => {
  const link = `/serials/${serial.serial}`;
  if (!serial.valid) {
    return (
      <InvalidLink to={link} className="nav-link">
        {serial.serial}
      </InvalidLink>
    );
  }
  return (
    <Link to={link} className="nav-link">
      {serial.serial}
    </Link>
  );
};

const renderResults = (page, total, count) => {
  if (total) {
    return (
      <h3>{count} results: page {page} of {total}</h3>
    );
  }
  return (
    <h3>No results</h3>
  );
};

const Serials = ({ data, page, total, count }) => {
  return data && (
    <div className="container">
      <table>
        <thead>
          <tr>
            <th>Serial</th>
            <th>Status</th>
            <th>Name</th>
            <th>Organization</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {data &&
           data.map(line => {
             return (
               <tr key={line.serial}>
                 <td>
                   {renderSerial(line)}
                 </td>
                 <td>{getSerialStatus(line)}</td>
                 <td>{shortText(line.name, 32)}</td>
                 <td>{shortText(line.organization, 32)}</td>
                 <td>{shortText(line.comment, 40)}</td>
               </tr>
             );
           })}
        </tbody>
      </table>
      {renderResults(page, total, count)}
    </div>
  );
};

Serials.propTypes = {
  data: PropTypes.array,
  page: PropTypes.number,
  total: PropTypes.number,
  // size: PropTypes.number,
  count: PropTypes.number,
};

Serials.defaultProps = {
  data: null,
  page: 0,
  total: 0,
  // size: 0,
  count: 0,
};

export default Serials;
