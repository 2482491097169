import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import Textarea from 'react-validation/build/textarea';
import { useParams } from 'react-router-dom';
import CheckButton from 'react-validation/build/button';
import download from 'downloadjs';
import { isEmail } from 'validator';

import {
  validInteger,
  validEmail,
  validMac,
  validPhone,
  vactivations,
} from './validation';

import SerialService from '../services/serial.service';
import ProductService from '../services/product.service';
import UserService from '../services/user.service';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const downloadReceipt = (serial, mac) => {
  if (serial && mac) {
    const macString = mac.toUpperCase().replace(/[^0-9A-F]/g, '');
    return SerialService.activateSerial({ mac, serial }).then(response => {
      const receipt = response.data;
      if (receipt && response.headers['content-type'] === 'application/octet-stream') {
        download(receipt, `${macString}.receipt`, 'application/octet-stream');
      }
      return response;
    });
  }
  return Promise.reject(new Error('MAC and serial are required'));
};

// eslint-disable-next-line react-hooks/exhaustive-deps
const useMount = (fn, arr = []) => useEffect(fn, arr);

const userStyle = {
  color: 'blue',
  fontSize: '80%'
};

const SerialDetail = () => {
  const { id } = useParams();

  const form = useRef();
  const checkBtn = useRef();
  const userField = useRef();

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState('');
  const [editing, setEditing] = useState(false);

  const [serial, setSerial] = useState(null);
  const [productDescription, setProductDescription] = useState('');
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [organization, setOrganization] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [comments, setComments] = useState('');
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState('');

  const [editingActivations, setEditingActivations] = useState(false);
  const [activations, setActivations] = useState(2);
  const [mac, setMac] = useState('');

  const reloadSerial = () => {
    SerialService.getSerial(id).then(
      async response => {
        setEditing(false);
        setSerial(response.data);
        setStatus(response.data.status || (response.data.valid ? 'valid' : 'unknown'));
        setName(response.data.name || '');
        setOrganization(response.data.organization || '');
        setPhone(response.data.phone || '');
        setEmail(response.data.email || '');
        if (response.data.expiration) {
          setExpireDate(new Date(response.data.expiration));
        }
        setComments(response.data.comment);

        setActivations(response.data.count);
        setMac('');
        const { product: serialProduct, version: serialVersion } = response.data;
        if (serialProduct && serialVersion) {
          const rsp = await ProductService.getProduct(serialProduct, serialVersion);
          setProductDescription(rsp.data.description || '');
        }
        if (response.data.user) {
          await UserService.getUser(response.data.user)
            .then(userResponse => {
              setUserEmail(userResponse.data.email || '');
              setUser(userResponse.data);
            })
            .catch(() => {
              setUserEmail('');
              setUser(null);
            });
        } else {
          setUserEmail('');
          setUser(null);
        }
      },
      error => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
  };

  useMount(() => { reloadSerial(); }, [id]);

  const onChangeStatus = e => {
    const newStatus = e.target.value;
    setStatus(newStatus);
  };

  const onChangeName = e => {
    const newName = e.target.value;
    setName(newName);
  };

  const onChangeOrganization = e => {
    const newOrganization = e.target.value;
    setOrganization(newOrganization);
  };

  const onChangePhone = e => {
    const newPhone = e.target.value;
    setPhone(newPhone);
  };

  const onChangeEmail = e => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const checkUser = newEmail => {
    if (!newEmail) {
      setUser(null);
    } else if (!isEmail(newEmail)) {
      setUser(null);
    } else {
      UserService.getUser(newEmail).then(
        newUser => {
          setUser(newUser.data);
        },
        () => {
          setUser(null);
        });
    }
  };

  const onChangeUserEmail = e => {
    const newEmail = e.target.value;
    setUserEmail(newEmail);
    checkUser(newEmail);
  };

  const onChangeComments = e => {
    const newComments = e.target.value;
    setComments(newComments);
  };

  const onEdit = () => {
    setEditing(true);
  };

  const onEditActivations = () => {
    setEditingActivations(!editingActivations);
  };

  const onChangeActivations = e => {
    const newActivations = parseInt(e.target.value, 10);
    if (!Number.isNaN(newActivations)) {
      setActivations(newActivations);
    }
  };

  const onChangeMac = e => {
    const newMac = e.target.value;
    setMac(newMac);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();
    if (userEmail && !user) {
      setMessage('This is not a valid user.');
      return;
    }

    const formattedDate = expireDate && expireDate.toJSON();

    const valid = status === 'valid' || !status;

    if (checkBtn.current.context._errors.length === 0) {
      const { product, version } = serial;
      const options = {
        product,
        version,
        serial: serial.serial,
        count: serial.count,
        valid,
        status,
        name,
        organization,
        email: email || null,
        phone: phone || null,
        comments,
        expiration: formattedDate || null, // null will clear expiration
      };
      return SerialService.getSerial(id)
        .then(response => {
          return SerialService.modifySerial(options).then(() => response.data.user);
        })
        .then(currentUser => {
          if (!currentUser) {
            return null;
          }
          return UserService.getUser(currentUser).then(response => response.data.email)
            .catch(() => null);
        })
        .then(currentEmail => {
          if (userEmail !== currentEmail) {
            return SerialService.assignSerial({ email: userEmail || '', serial: serial.serial })
              .catch(() => {});
          }
        })
        .then(() => {
          setSuccessful(true);
          reloadSerial();
        })
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        });
    }
  };

  const handleChangeActivations = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const { product, version } = serial;
      const options = {
        product,
        version,
        serial: serial.serial,
        count: activations,
      };
      return SerialService.modifySerial(options)
        .then(() => {
          setSuccessful(true);
          setEditingActivations(false);
          reloadSerial();
        })
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        });
    }
  };

  const handleAddActivation = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      return downloadReceipt(serial.serial, mac)
        .then(() => {
          setSuccessful(true);
          reloadSerial();
        })
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        });
    }
  };

  const handleClearActivations = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    if (checkBtn.current.context._errors.length === 0) {
      return SerialService.clearActivations(serial.serial)
        .then(() => {
          setSuccessful(true);
          reloadSerial();
        })
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        });
    }
  };

  const renderActivations = () => {
    return (
      <div>
        {serial.activations.length > 0 && (
          <div className="form-group" onClick={handleClearActivations}>
            <button className="btn btn-secondary btn-block">
              <span>Clear</span>
            </button>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="activations">Number of activations</label>
          <Input
            type="text"
            className="form-control"
            name="activations"
            value={activations}
            onChange={onChangeActivations}
            validations={[validInteger, vactivations]}
          />
        </div>

        {serial.activations.length !== activations && (
          <div className="form-group" onClick={handleChangeActivations}>
            <button className="btn btn-secondary btn-block">
              <span>Change</span>
            </button>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="mac">Add MAC</label>
          <Input
            type="text"
            className="form-control"
            name="mac"
            value={mac}
            onChange={onChangeMac}
            validations={[validMac]}
          />
        </div>

        {mac && (
          <div className="form-group" onClick={handleAddActivation}>
            <button className="btn btn-secondary btn-block">
              <span>Add Activation</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  if (!serial) {
    return null;
  }
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <Form onSubmit={handleSubmit} ref={form}>
          <div>
            <div className="form-group">
              <div>
                {serial.serial}
              </div>
              <div>
                {productDescription}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="status">Status</label>
              <Select
                name="status"
                value={status}
                onChange={onChangeStatus}
                disabled={!editing}
              >
                <option value="valid">Valid</option>
                <option value="fraud">Fraud</option>
                <option value="refund">Refund</option>
                <option value="swap">One time swap</option>
                <option value="other">Other</option>
                <option value="unknown">Unknown</option>
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="expireDate">Expiration Date</label>
              <DatePicker
                selected={expireDate}
                onSelect={date => console.log(date.toJSON())}
                onChange={date => setExpireDate(date)}
                dateFormat="yyyy/MM/dd"
                disabled={!editing}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                value={name}
                onChange={onChangeName}
                disabled={!editing}
              />
            </div>

            <div className="form-group">
              <label htmlFor="organization">Organization</label>
              <Input
                type="text"
                className="form-control"
                name="organization"
                value={organization}
                onChange={onChangeOrganization}
                disabled={!editing}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <Input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={onChangePhone}
                validations={[validPhone]}
                disabled={!editing}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[validEmail]}
                disabled={!editing}
              />
            </div>

            <div className="form-group">
              <label htmlFor="userEmail">Lock to User</label>
              <Input
                type="text"
                className="form-control"
                name="userEmail"
                value={userEmail}
                onChange={onChangeUserEmail}
                validations={[validEmail]}
                ref={userField}
                disabled={!editing}
              />
            </div>
            {user && (
              <span style={userStyle}>
                <p>{user.firstname} {user.lastname}</p>
                <p>{user.company}</p>
              </span>
            )}
            {!user && userEmail && isEmail(userEmail) && (
              <div className="alert alert-danger" role="alert">
                This is not a valid user.
              </div>
            )}

            <div className="form-group">
              <div>
                Activations {serial.activations.length}/{serial.count}
                <button type="button" className="btn btn-secondary" onClick={onEditActivations}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                </button>
                <button type="button" className="btn btn-secondary" disabled={serial.activations.length === 0} onClick={handleClearActivations}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </button>
              </div>
              {editingActivations && renderActivations()}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="comments">Comments/Metadata</label>
            <Textarea
              type="text"
              className="form-control"
              name="comments"
              value={comments}
              onChange={onChangeComments}
              disabled={!editing}
            />
          </div>

          {editing && (
          <div className="form-group">
            <button className="btn btn-primary btn-block">
              <span>Save</span>
            </button>
          </div>
          )}

          {!editing && (
            <div className="form-group" onClick={onEdit}>
              <button className="btn btn-secondary btn-block">
                <span>Edit</span>
              </button>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default SerialDetail;
