import axios from '../utils/axios';
import authHeader from './auth-header';
import { ADMIN_URL } from '../config/vars';

const API_URL = `${ADMIN_URL}/users`;

const getUser = user => {
  const params = {};
  return axios.get(`${API_URL}/${user}`, { headers: authHeader(), params });
};

export default {
  getUser
};
