import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';
import { Link } from 'react-router-dom';
import download from 'downloadjs';
import { isEmail } from 'validator';

import {
  required,
  validInteger,
  validEmail,
  validMac,
  validPhone,
  vquantity,
  vactivations,
} from './validation';

import SerialService from '../services/serial.service';
import ProductService from '../services/product.service';
import UserService from '../services/user.service';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const editBulkSerials = (serials, options = {}) => {
  return Promise.resolve().then(() => (
    (function loop(i) {
      if (i >= serials.length) {
        return true;
      }
      const serial = serials[i];
      return SerialService.modifySerial({ ...serial, ...options })
        .then(() => loop(i + 1));
    })(0)
  ));
};

const createBulkSerials = (count, options = {}) => {
  const { product } = options;
  const [name, version] = product.split('/');
  return SerialService.createBulkSerials({ product, count })
    .then(response => {
      if (response && response.data) {
        if (count > 1) {
          download(response.data, `${product} serials.txt`, 'text/plain');
        }
        return response.data.split('\n').filter(serial => !!serial).map(serial => (
          {
            product: name,
            version,
            serial
          }
        ));
      }
      return [];
    })
    .then(serials => {
      if (options.name || options.organization || options.phone || options.email
          || options.activations !== 2 || options.comments) {
        return editBulkSerials(serials, options).then(() => serials);
      }
      return serials;
    });
};

const createTrialSerials = (quantity, options = {}) => {
  const { userEmail } = options;
  return Promise.resolve().then(() => (
    (function loop(count, serials = []) {
      if (count === 0) {
        return Promise.resolve(serials);
      }
      return SerialService.createSerial(options)
        .then(response => {
          if (userEmail) {
            return SerialService.assignSerial({ email: userEmail, serial: response.data.serial }).then(() => response);
          }
          return response;
        })
        .then(response => loop(count - 1, serials.concat([response.data])));
    })(quantity)
  ));
};

const assignSerials = (serials, email) => {
  return Promise.resolve().then(() => (
    (function loop(serialQueue) {
      if (serialQueue.length === 0) {
        return true;
      }
      const serial = serialQueue.pop();
      return SerialService.assignSerial({ email, serial })
        .then(() => loop(serialQueue));
    })(serials)
  ));
};

const downloadReceipt = (serial, mac) => {
  if (serial && mac) {
    const macString = mac.toUpperCase().replace(/[^0-9A-F]/g, '');
    return SerialService.activateSerial({ mac, serial }).then(response => {
      const receipt = response.data;
      if (receipt && response.headers['content-type'] === 'application/octet-stream') {
        download(receipt, `${macString}.receipt`, 'application/octet-stream');
      }
      return response;
    });
  }
  return Promise.reject(new Error('MAC and serial are required'));
};

const renderSerial = serial => {
  const link = `/serials/${serial.serial}`;
  return (
    <Link to={link} className="nav-link" key={serial.serial}>
      {serial.serial}
    </Link>
  );
};

const userStyle = {
  color: 'blue',
  fontSize: '80%'
};

const Create = (/* props */) => {
  const form = useRef();
  const checkBtn = useRef();
  const userField = useRef();

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState('');

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [legacyProducts, setLegacyProducts] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [expireDate, setExpireDate] = useState('');
  const [activations, setActivations] = useState(2);
  const [name, setName] = useState('');
  const [organization, setOrganization] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [mac, setMac] = useState('');
  const [comments, setComments] = useState('');

  const [lastSerials, setLastSerials] = useState([]);

  const reloadProducts = (active = true) => {
    ProductService.getProducts({ active }).then(
      response => {
        setProducts(response.data);
      },
      error => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
  };

  useEffect(() => {
    reloadProducts(true);
  }, []);

  const onChangeProduct = e => {
    const newProduct = e.target.value;
    setProduct(newProduct);
  };

  const onLegacyProductsChange = e => {
    const newLegacyProducts = e.target.checked;
    setLegacyProducts(newLegacyProducts);
    reloadProducts(!newLegacyProducts);
  };

  const onChangeQuantity = e => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!Number.isNaN(newQuantity)) {
      setQuantity(newQuantity);
    }
  };

  const onChangeActivations = e => {
    const newActivations = parseInt(e.target.value, 10);
    if (!Number.isNaN(newActivations)) {
      setActivations(newActivations);
    }
  };

  const onChangeName = e => {
    const newName = e.target.value;
    setName(newName);
  };

  const onChangeOrganization = e => {
    const newOrganization = e.target.value;
    setOrganization(newOrganization);
  };

  const onChangePhone = e => {
    const newPhone = e.target.value;
    setPhone(newPhone);
  };

  const checkUser = newEmail => {
    if (!newEmail) {
      setUser(null);
    } else if (!isEmail(newEmail)) {
      setUser(null);
    } else {
      UserService.getUser(newEmail).then(
        newUser => {
          setUser(newUser.data);
        },
        () => {
          setUser(null);
        });
    }
  };

  const onChangeEmail = e => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const onChangeUserEmail = e => {
    const newEmail = e.target.value;
    setUserEmail(newEmail);
    checkUser(newEmail);
  };

  const onChangeMac = e => {
    const newMac = e.target.value;
    setMac(newMac);
  };

  const onChangeComments = e => {
    const newComments = e.target.value;
    setComments(newComments);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();
    if (userEmail && !user) {
      setMessage('This is not a valid user.');
      return;
    }

    const formattedDate = expireDate && expireDate.toJSON();

    if (checkBtn.current.context._errors.length === 0) {
      const options = {
        product,
        name: name || null,
        organization: organization || null,
        phone: phone || null,
        email: email || null,
        expiration: formattedDate || null,
        count: activations,
        comments,
      };
      return (formattedDate ? createTrialSerials(quantity, options) : createBulkSerials(quantity, options))
        .then(serials => {
          setLastSerials(serials);
          setSuccessful(true);
          return serials.map(s => s.serial);
        })
        .then(serials => {
          if (userEmail && serials && serials.length > 0) {
            return assignSerials(serials, userEmail).then(() => serials);
          }
          return serials;
        })
        .then(serials => {
          if (mac && serials && serials.length > 0) {
            return downloadReceipt(serials[0], mac).then(() => serials);
          }
          return serials;
        })
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
          return [];
        });
    }
  };

  const createNewButton = () => {
    setSuccessful(!successful);
  };

  const renderSerials = () => {
    if (!lastSerials || lastSerials.length === 0) {
      return null;
    }
    const [{ product: product0, version: version0 }] = lastSerials;
    return (
      <div className="">
        <h2 className="text-center">You created a license for: {product0}/{version0}</h2>
        {lastSerials.map(serial => renderSerial(serial))}
        <button onClick={createNewButton} className="btn btn-primary btn-block">Create another license</button>
      </div>
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {successful && renderSerials()}
        {!successful && (
        <Form onSubmit={handleSubmit} ref={form}>
          <div>
            <div className="form-group">
              <label htmlFor="product">Product</label>
              <Select
                name="product"
                value={product}
                onChange={onChangeProduct}
                validations={[required]}
              >
                <option value="">Select a product</option>
                {products && products.map(p => <option value={`${p.name}/${p.version}`} key={`${p.name}/${p.version}`}>{p.description}</option>)}
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="legacyProducts">Legacy Products</label>
              <Input
                type="checkbox"
                value="0"
                name="legacyProducts"
                checked={legacyProducts}
                onChange={onLegacyProductsChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="quantity">Quantity</label>
              <Input
                type="text"
                className="form-control"
                name="quantity"
                value={quantity}
                onChange={onChangeQuantity}
                validations={[required, validInteger, vquantity]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="expireDate">Expiration Date</label>
              <DatePicker selected={expireDate} onSelect={date => console.log(date.toJSON())} onChange={date => setExpireDate(date)} dateFormat="yyyy/MM/dd" />
            </div>

            <div className="form-group">
              <label htmlFor="activations">Number of activations</label>
              <Input
                type="text"
                className="form-control"
                name="activations"
                value={activations}
                onChange={onChangeActivations}
                validations={[required, validInteger, vactivations]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                value={name}
                onChange={onChangeName}
              />
            </div>

            <div className="form-group">
              <label htmlFor="organization">Organization</label>
              <Input
                type="text"
                className="form-control"
                name="organization"
                value={organization}
                onChange={onChangeOrganization}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <Input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={onChangePhone}
                validations={[validPhone]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[validEmail]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="userEmail">Lock to User</label>
              <Input
                type="text"
                className="form-control"
                name="userEmail"
                value={userEmail}
                onChange={onChangeUserEmail}
                ref={userField}
                validations={[validEmail]}
              />
            </div>
            {user && (
              <span style={userStyle}>
                <p>{user.firstname} {user.lastname}</p>
                <p>{user.company}</p>
              </span>
            )}
            {!user && userEmail && isEmail(userEmail) && (
              <div className="alert alert-danger" role="alert">
                This is not a valid user.
              </div>
            )}

            <div className="form-group">
              <label htmlFor="mac">Lock to specific MAC</label>
              <Input
                type="text"
                className="form-control"
                name="mac"
                value={mac}
                onChange={onChangeMac}
                validations={[validMac]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="comments">Comments/Metadata</label>
              <Textarea
                type="text"
                className="form-control"
                name="comments"
                value={comments}
                onChange={onChangeComments}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block">Create</button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div
                className={successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
        )}
      </div>
    </div>
  );
};

export default Create;
