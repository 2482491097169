import React from 'react';
import { isEmail, isMobilePhone } from 'validator';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validInteger = value => {
  // If a floating point number is entered, parseInt will read the leading integer.
  const v = parseFloat(value);
  if (!Number.isInteger(v)) {
    return (
      <div className="alert alert-danger" role="alert">
        An integer is required.
      </div>
    );
  }
};

const validEmail = value => {
  if (value && !isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const validPhone = value => {
  if (value && !isMobilePhone(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid phone.
      </div>
    );
  }
};

const validPhoneUS = value => {
  if (value && !isMobilePhone(value, ['en-US'])) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid US phone.
      </div>
    );
  }
};

const isMac = value => {
  const v = value.toUpperCase();
  return /^[0-9A-F]{12}$/.test(v) || /^([0-9A-F][0-9A-F]:){5}[0-9A-F][0-9A-F]$/.test(v);
};

const validMac = value => {
  if (value && !isMac(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid MAC address.
      </div>
    );
  }
};

const vquantity = value => {
  if (parseInt(value, 10) < 1) {
    return (
      <div className="alert alert-danger" role="alert">
        The quantity must be at least 1.
      </div>
    );
  }
};

const vactivations = value => {
  if (parseInt(value, 10) < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        The activations must be at least 0.
      </div>
    );
  }
};

const vdays = value => {
  if (parseInt(value, 10) < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        The days must be at least 0.
      </div>
    );
  }
};

export {
  required,
  validInteger,
  validEmail,
  validPhone,
  validPhoneUS,
  validMac,
  vquantity,
  vactivations,
  vdays,
};
