import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import CheckButton from 'react-validation/build/button';

import {
  required,
  validInteger,
  validMac,
  vdays,
} from './validation';

import SerialService from '../services/serial.service';
import ProductService from '../services/product.service';

const createActivation = (options = {}) => {
  const { product } = options;
  const [name, version] = product.split('/');
  return SerialService.createLegacyActivation(options)
    .then(response => {
      return [{
        product: name,
        version,
        ...response.data,
      }];
    });
};

const Legacy = (/* props */) => {
  const form = useRef();
  const checkBtn = useRef();

  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState('');

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [days, setDays] = useState(0);
  const [mac, setMac] = useState('');

  const [lastActivations, setLastActivations] = useState([]);

  const reloadProducts = () => {
    ProductService.getProducts({ legacy: true }).then(
      response => {
        setProducts(response.data);
      },
      error => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
  };

  useEffect(() => {
    reloadProducts();
  }, []);

  const onChangeProduct = e => {
    const newProduct = e.target.value;
    setProduct(newProduct);
  };

  const onChangeDays = e => {
    const newDays = parseInt(e.target.value, 10);
    if (!Number.isNaN(newDays)) {
      setDays(newDays);
    }
  };

  const onChangeMac = e => {
    const newMac = e.target.value;
    setMac(newMac);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setMessage('');
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const options = { product, days, mac };
      return createActivation(options)
        .then(activations => {
          setLastActivations(activations);
          setSuccessful(true);
        })
        .catch(error => {
          console.error(error);
          const resMessage = (
            error.response &&
              error.response.data &&
              error.response.data.message) ||
                error.message ||
                error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        });
    }
  };

  const createNewButton = () => {
    setSuccessful(!successful);
  };

  const renderActivations = () => {
    if (!lastActivations || lastActivations.length === 0) {
      return null;
    }
    const [{ product: product0, version: version0 }] = lastActivations;
    return (
      <div className="">
        <h2 className="text-center">You created an activation for: {product0}/{version0}</h2>
        {lastActivations.map(activation => <p className="lead" key={activation.key}>{activation.key}</p>)}
        <button onClick={createNewButton} className="btn btn-primary btn-block">Create another activation</button>
      </div>
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {successful && renderActivations()}
        {!successful && (
        <Form onSubmit={handleSubmit} ref={form}>
          <div>
            <p>This is for the old licensing system.  Use 0 days for a permanent activation.</p>
            <div className="form-group">
              <label htmlFor="product">Product</label>
              <Select
                name="product"
                value={product}
                onChange={onChangeProduct}
                validations={[required]}
              >
                <option value="">Select a product</option>
                {products && products.map(p => <option value={`${p.name}/${p.version}`} key={`${p.name}/${p.version}`}>{p.description}</option>)}
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="days">Number of days</label>
              <Input
                type="text"
                className="form-control"
                name="days"
                value={days}
                onChange={onChangeDays}
                validations={[required, validInteger, vdays]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="mac">Lock to specific MAC</label>
              <Input
                type="text"
                className="form-control"
                name="mac"
                value={mac}
                onChange={onChangeMac}
                validations={[validMac]}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block">Create</button>
            </div>
          </div>

          {message && (
            <div className="form-group">
              <div
                className={successful ? 'alert alert-success' : 'alert alert-danger'}
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
        )}
      </div>
    </div>
  );
};

export default Legacy;
